import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { SEO } from "gatsby-theme-core";
import { Layout, HeaderOne, FooterOne } from "gatsby-theme-layout";
import {
    VideoStreamingContainerTwo,
    HeroSection01,
    SliderBanner01,
    BannerSection3,
} from "gatsby-theme-container";
import { normalizedData } from "@streamo/helper/methods";

const StreamingIndex = ({ data }) => {
    const content = normalizedData(data?.page?.content || []);
    return (
        <Layout>
            <SEO pageName="Streaming" />
            <HeaderOne data={{ ...data?.header, ...data.navigation }} />
            <HeroSection01 data={{ items: data?.featuredMoviesPost?.nodes }} />
            <VideoStreamingContainerTwo
                data={{
                    ...content?.["latest-movies-section"],
                    items: data?.allLatestMoviesPost?.nodes,
                }}
            />
            <VideoStreamingContainerTwo
                data={{
                    ...content?.["horror-series-section"],
                    items: data?.allVideoStreamingHorrorSeries?.nodes,
                }}
            />
            <VideoStreamingContainerTwo
                data={{
                    ...content?.["old-movie-section"],
                    items: data?.allOldMoviesPost?.nodes,
                }}
            />
            <SliderBanner01
                data={{
                    items: data?.horrorSeriesBanner?.nodes,
                }}
            />
            <VideoStreamingContainerTwo
                data={{
                    ...content?.["watch-with-family-section"],
                    items: data?.allVideoStreamingFamilyMovie?.nodes,
                }}
            />
            <BannerSection3
                data={{
                    items: data?.romanticMovieBanner?.nodes,
                }}
            />
            <VideoStreamingContainerTwo
                data={{
                    ...content?.["award-winning-movie-section"],
                    items: data?.awardWinningMovie?.nodes,
                }}
            />
            <FooterOne data={{ ...data?.footer, ...data?.contact }} />
        </Layout>
    );
};

export const query = graphql`
    query StreamingThreeMovieQuery {
        header: general(section: { eq: "header-01" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu-streaming" }) {
            ...Menu01
        }
        footer: general(section: { eq: "footer-01" }) {
            ...Footer01
        }
        contact: general(section: { eq: "contact" }) {
            ...Contact01
        }
        page(title: { eq: "streaming-home-two" }) {
            content {
                ...PageContent
            }
        }
        featuredMoviesPost: allVideoStreamingMovie(
            filter: {
                category: { elemMatch: { slug: { eq: "romantic" } } }
                is_featured: { eq: true }
            }
        ) {
            nodes {
                ...VideoStreamingMovie
            }
        }
        allLatestMoviesPost: allVideoStreamingMovie(
            sort: { fields: date, order: DESC }
            filter: { comingSoon: { eq: false } }
        ) {
            nodes {
                ...VideoStreamingMovie
            }
        }
        allOldMoviesPost: allVideoStreamingMovie(
            filter: { oldMovie: { eq: true } }
        ) {
            nodes {
                ...VideoStreamingMovie
            }
        }
        allVideoStreamingHorrorSeries: allVideoStreamingSeries(
            sort: { fields: date, order: DESC }
            filter: { category: { elemMatch: { slug: { eq: "horror" } } } }
        ) {
            nodes {
                ...VideoStreamingSeries
            }
        }
        horrorSeriesBanner: allVideoStreamingSeries(
            sort: { fields: date, order: DESC }
            filter: {
                category: { elemMatch: { slug: { eq: "horror" } } }
                is_featured: { eq: true }
            }
        ) {
            nodes {
                ...VideoStreamingSeries
            }
        }
        romanticMovieBanner: allVideoStreamingMovie(
            sort: { fields: date, order: DESC }
            filter: {
                category: { elemMatch: { slug: { eq: "comedy" } } }
                is_featured: { eq: true }
            }
        ) {
            nodes {
                ...VideoStreamingMovie
            }
        }
        allVideoStreamingFamilyMovie: allVideoStreamingMovie(
            filter: { certification: { eq: "A" } }
        ) {
            nodes {
                ...VideoStreamingMovie
            }
        }
        awardWinningMovie: allVideoStreamingMovie(
            filter: { awardWinning: { eq: true } }
        ) {
            nodes {
                ...VideoStreamingMovie
            }
        }
    }
`;

StreamingIndex.propTypes = {
    data: PropTypes.shape({
        header: PropTypes.shape({}),
        navigation: PropTypes.shape({}),
        footer: PropTypes.shape({}),
        contact: PropTypes.shape({}),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        featuredMoviesPost: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allLatestMoviesPost: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allVideoStreamingHorrorSeries: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allOldMoviesPost: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allVideoStreamingFamilyMovie: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        horrorSeriesBanner: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        awardWinningMovie: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        romanticMovieBanner: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};
export default StreamingIndex;
